import { delay } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';

import auth from '../actions/auth';

function* checkZendeskLoaded() {
  while (true) {
    yield delay(1000);
    if (window.zE && window.zE.identify) {
      break;
    }
  }
}

function* setUserZendesk() {
  yield call(checkZendeskLoaded);
  const { email, name, firstSurname } = yield select(
    state => state.auth.userData.user
  );

  window.zE.show();
  window.zE.identify({
    name: `${name} ${firstSurname}`,
    email,
  });
}

function hideZendesk() {
  window.zE.hide();
}

function* watchUserDataFetchCompleted() {
  yield takeLatest(auth.types.AUTH_USER_DATA_FETCH.COMPLETED, setUserZendesk);
}

function* watchLogoutCompleted() {
  yield takeLatest(auth.types.AUTH_LOGOUT.COMPLETED, hideZendesk);
}

export default {
  watchUserDataFetchCompleted,
  watchLogoutCompleted,
};
